import React from "react";
import TechExpertise from "../TechExpertise/TechExpertise";
import OurProjects from "../OurProjects/OurProjects";
import ToolsNAccelerators from "../ToolsNAccelerators/ToolsNAccelerators";

function MultiCardSection() {
  return (
    <div className="section section--bg-dot-curve">
      <TechExpertise />
      <OurProjects />
      <ToolsNAccelerators />
    </div>
  );
}

export default MultiCardSection;
