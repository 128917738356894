import React from "react";

function TechExpertise() {
  return (
    <section className="section section--technologies --section-normal-tb-padding">
      <div className="container">
        <div className="container__row">
          <div className="container__col-xs-12">
            <h2 className="container__heading">Technologies we are experts at</h2>
          </div>
          <div className="container__col-md-6">
            <div className="card">
              <img
                className="card__image"
                src="../../solutions/solutions/technologies/adobe-experience-manager.png"
                alt="HASHOUT"
              />
              <h5 className="card__title">Adobe Experience Manager</h5>
              <div className="card__description">
                A Digital Experience Platform (DXP) combining the power of DAM
                and CMS to get personalized, content-led experiences into the
                market faster.
              </div>
              <a
                className="card__action --action-link"
                href="/adobe-experience-manager"
              >
                Learn more
              </a>
            </div>
          </div>
          <div className="container__col-md-6">
            <div className="card">
              <img
                className="card__image"
                src="../../solutions/solutions/technologies/jamstack.png"
                alt="HASHOUT"
              />
              <h5 className="card__title">Jamstack</h5>
              <div className="card__description">
                An architecture to run a fully dynamic site while the actual
                assets are pre-rendered static files deployed on CDN.
              </div>
              <a className="card__action --action-link" href="/jam-stack">
                Learn more
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    // Modular scss call begins here
    // <div
    // 	className={`${styles["section"]} ${styles["section__technologies"]} ${styles["__section_normal_tb_padding"]}`}
    // >
    // 	<div className={`${styles["container"]}`}>
    // 		<div className={`${styles["container__row"]}`}>
    // 			<div
    // 				className={`${styles["container__row__col_xs_12"]} ${styles["section__container__row__heading"]}`}
    // 			>
    // 				<h2>Technologies we are experts at</h2>
    // 			</div>
    // 			<div className={`${styles["container__row__col_md_6"]}`}>
    // 				<div className={`${styles["card"]} ${styles["__margin_left_0"]}`}>
    // 					<img
    // 						className={`${styles["card__image"]}`}
    // 						src="../../solutions/solutions/technologies/adobe-experience-manager.png"
    // 						alt="HASHOUT"
    // 					/>
    // 					<h5 className={`${styles["card__title"]}`}>
    // 						Adobe Experience Manager
    // 					</h5>
    // 					<div className={`${styles["card__description"]}`}>
    // 						A Digital Experience Platform (DXP) combining the power of DAM
    // 						and CMS to get personalized, content-led experiences into the
    // 						market faster.
    // 					</div>
    // 					<a
    // 						className={`${styles["card__action"]} ${styles["__action_link"]}`}
    // 						href=""
    // 					>
    // 						Learn more
    // 					</a>
    // 				</div>
    // 			</div>
    // 			<div className={`${styles["container__row__col_md_6"]}`}>
    // 				<div className={`${styles["card"]} ${styles["__margin_right_0"]}`}>
    // 					<img
    // 						className={`${styles["card__image"]}`}
    // 						src="../../solutions/solutions/technologies/jamstack.png"
    // 						alt="HASHOUT"
    // 					/>
    // 					<h5 className={`${styles["card__title"]}`}>Jamstack</h5>
    // 					<div className={`${styles["card__description"]}`}>
    // 						An architecture to run a fully dynamic site while the actual
    // 						assets are pre-rendered static files deployed on CDN.
    // 					</div>
    // 					<a
    // 						className={`${styles["card__action"]} ${styles["__action_link"]}`}
    // 						href="javascript:void(0);"
    // 					>
    // 						Learn more
    // 					</a>
    // 				</div>
    // 			</div>
    // 		</div>
    // 	</div>
    // </div>
  );
}

export default TechExpertise;
