import React from "react";

function ToolsNAccelerators() {
  return (
    <section className="section section--tools section--tools-bg --section-normal-tb-padding">
      <div className="container">
        <div className="container__row">
          <div className="container__col-xs-12">
            <h2 className="container__heading">Tools and Accelerators</h2>
            <div className="container__row">
              <div className="container__col-md-7">
                <p className="container__content">
                  Our continously efforts to build common repeatable solutions
                  as tools ensures providing faster time to market and redueced
                  effor on our engagements. These tools are also available to be
                  distrubted stand-alone, reach out to us to learn more.
                </p>
              </div>
            </div>
          </div>

          <div className="container__col-md-4 container__col-xs-12">
            <div className="card ">
              <img
                className="card__image"
                src="../../solutions/solutions/tools/aem-algolia.png"
                alt="Adobe Experience Manager"
              />
              <a
                className=""
                href="/blog/Integrating-Algolia-Search-for-AEM-Sites"
                target="_blank"
              >
                <h5 className="card__title">AEM + Algolia Connector</h5>
              </a>
              <p className="card__description">
                Push connector to Algolia for Content and Assets on AEM.
              </p>
            </div>
          </div>
          <div className="container__col-md-4 container__col-xs-12">
            <div className="card">
              <img
                className="card__image"
                src="../../solutions/solutions/tools/aem-salesforce.png"
                alt="AEM-Salesforce Connector"
              />
              <a
                href="../../../Hashout-Guide-to-connecting-AEM-and-CRM-for-self-service.pdf"
                target="_blank"
              >
                <h5 className="card__title">AEM-Salesforce Connector</h5>
              </a>
              <p className="card__description">
                Sync content from Salesforce Knowledge to AEM.
              </p>
            </div>
          </div>
          <div className="container__col-md-4 container__col-xs-12">
            <div className="card">
              <img
                className="card__image"
                src="../../solutions/solutions/tools/custom-events-framework.png"
                alt="Custom Events Framework"
              />
              <a
                href="/blog/Understanding-Google-Custom-Search-Engine(CSE)-JSON-API"
                target="_blank"
              >
                <h5 className="card__title">Custom Events Framework</h5>
              </a>
              <p className="card__description">
                Advanced event collector to automatic capture interaction events
                to your own database and reporting tools.
              </p>
            </div>
          </div>
          <div className="container__col-md-4 container__col-xs-12">
            <div className="card">
              <img
                className="card__image"
                src="../../solutions/solutions/tools/broken-link-checker.png"
                alt="Broken Link Checker"
              />
              <a href="https://sitecheck.hashouttech.com/" target="_blank">
                <h5 className="card__title">Broken Link Checker</h5>
              </a>
              <p className="card__description">
                Fast, scalable tool to check broken links, images on your
                website...
              </p>
            </div>
          </div>
          <div className="container__col-md-4 container__col-xs-12">
            <div className="card">
              <img
                className="card__image"
                src="../../solutions/solutions/tools/virtual-tradeshow.png"
                alt="Virtual Tradeshow"
              />
              <a href="/virtual-tradeshow" target="_blank">
                <h5 className="card__title">Virtual Tradeshow</h5>
              </a>
              <p className="card__description">
                Highly customizable 3D Virtual Platform to host events, trade
                shows, product exhibits.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    // Modular scss call begins here
    // <section
    // 	className={`${styles["section"]} ${styles["section__tools"]} ${styles["__section_normal_tb_padding"]}`}
    // >
    // 	<div className={`${styles["container"]}`}>
    // 		<div className={`${styles["container__row"]}`}>
    // 			<div
    // 				className={`${styles["container__row__col_xs_12"]} ${styles["section__container__row__heading"]}`}
    // 			>
    // 				<h2>Tools and Accelerators</h2>
    // 				<div className={`${styles["container__row"]}`}>
    // 					<div className={`${styles["container__row__col_md_7"]}`}>
    // 						<p>
    // 							Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam
    // 							tincidunt auctor quam id ornare. Morbi laoreet vulputate orci,
    // 							nec tincidunt ipsum blandit ut.
    // 						</p>
    // 					</div>
    // 				</div>
    // 			</div>

    // 			<div
    // 				className={`${styles["container__row__col_md_4"]} ${styles["container__row__col_xs_12"]}`}
    // 			>
    // 				<div
    // 					className={`${styles["card"]} ${styles["active"]} ${styles["__margin_left_0"]}`}
    // 				>
    // 					<img
    // 						className={`${styles["card__image"]}`}
    // 						src="../../solutions/solutions/tools/aem-algolia.png"
    // 						alt="Adobe Experience Manager"
    // 					/>
    // 					<a className={`${styles["active"]}`} href="javascript:void(0);">
    // 						<h5 className={`${styles["card__title"]}`}>
    // 							Adobe Experience Manager
    // 						</h5>
    // 					</a>
    // 					<p className={`${styles["card__description"]}`}>
    // 						Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
    // 						vel neque ut eros congue..
    // 					</p>
    // 				</div>
    // 			</div>
    // 			<div
    // 				className={`${styles["container__row__col_md_4"]} ${styles["container__row__col_xs_12"]}`}
    // 			>
    // 				<div className={`${styles["card"]}`}>
    // 					<img
    // 						className={`${styles["card__image"]}`}
    // 						src="../../solutions/solutions/tools/aem-salesforce.png"
    // 						alt="AEM-Salesforce Connector"
    // 					/>
    // 					<a href="javascript:void(0);">
    // 						<h5 className={`${styles["card__title"]}`}>
    // 							AEM-Salesforce Connector
    // 						</h5>
    // 					</a>
    // 					<p className={`${styles["card__description"]}`}>
    // 						Lorem ipsum dolor sit amet, consectetur adipiscing elit.
    // 					</p>
    // 				</div>
    // 			</div>
    // 			<div
    // 				className={`${styles["container__row__col_md_4"]} ${styles["container__row__col_xs_12"]}`}
    // 			>
    // 				<div className={`${styles["card"]} ${styles["__margin_right_0"]}`}>
    // 					<img
    // 						className={`${styles["card__image"]}`}
    // 						src="../../solutions/solutions/tools/custom-events-framework.png"
    // 						alt="Custom Events Framework"
    // 					/>
    // 					<a href="javascript:void(0);">
    // 						<h5 className={`${styles["card__title"]}`}>
    // 							Custom Events Framework
    // 						</h5>
    // 					</a>
    // 					<p className={`${styles["card__description"]}`}>
    // 						Lorem ipsum dolor sit amet, consectetur adipiscing elit.
    // 					</p>
    // 				</div>
    // 			</div>
    // 			<div
    // 				className={`${styles["container__row__col_md_4"]} container__row__col_xs_12"`}
    // 			>
    // 				<div className={`${styles["card"]} ${styles["__margin_left_0"]}`}>
    // 					<img
    // 						className={`${styles["card__image"]}`}
    // 						src="../../solutions/solutions/tools/broken-link-checker.png"
    // 						alt="Broken Link Checker"
    // 					/>
    // 					<a href="javascript:void(0);">
    // 						<h5 className={`${styles["card__title"]}`}>
    // 							Broken Link Checker
    // 						</h5>
    // 					</a>
    // 					<p className={`${styles["card__description"]}`}>
    // 						Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
    // 						vel neque ut eros congue..
    // 					</p>
    // 				</div>
    // 			</div>
    // 			<div
    // 				className={`${styles["container__row__col_md_4"]} ${styles["container__row__col_xs_12"]}`}
    // 			>
    // 				<div className={`${styles["card"]}`}>
    // 					<img
    // 						className={`${styles["card__image"]}`}
    // 						src="../../solutions/solutions/tools/virtual-tradeshow.png"
    // 						alt="Virtual Tradeshow"
    // 					/>
    // 					<a href="javascript:void(0);">
    // 						<h5 className={`${styles["card__title"]}`}>
    // 							Virtual Tradeshow
    // 						</h5>
    // 					</a>
    // 					<p className={`${styles["card__description"]}`}>
    // 						Lorem ipsum dolor sit amet, consectetur adipiscing elit.
    // 					</p>
    // 				</div>
    // 			</div>
    // 		</div>
    // 	</div>
    // </section>
  );
}
export default ToolsNAccelerators;
