import React, { useEffect, useState } from "react";
import ContactPopUp from "../../ContactPopUp";

function WebExperiences() {
	const [background, setBackground] = useState("");
	const [modalIsOpen, setIsOpen] = React.useState(false);
	function openModal() {
		setIsOpen(true);
	}

	function closeModal() {
		setIsOpen(false);
	}

	return (
		<section className="section section--banner section--gray-bg">
			<div className="container">
				<div className="container__row">
					<div className="container__col-md-6 --section-large-tb-padding">
						<h1 className="container__heading">Web Experiences </h1>
						<h2 className="container__sub-heading">
							Delivering fast, intuitive and <br />
							<span className="--red-color">custom-made web experiences!</span>
						</h2>
						<h6 className="container__content">
							Our solutions architects with vast expertise on a variety of
							platforms, frameworks, and technologies help you build your future
							web experience on a stack to deliver{" "}
							<span className="--red-color">
								incredible web experiences at scale.
							</span>
						</h6>
						<a onClick={openModal}>
							<button className="container__button">LET'S TALK</button>
						</a>
					</div>
					<div className="container__col-md-6 --flex-center">
						<img
							className="container__img --bg-absolute-right"
							// src="./images/solutions/web-experience.png"
							src="../../solutions/solutions/web-experience-1.png"
							alt="Web Experiences"
						/>
					</div>
				</div>
			</div>

			<ContactPopUp
				isOpen={modalIsOpen}
				onRequestClose={closeModal}
				title="Let's Connect"
				subTitle="Fill out this short form, and we'll get back to you within one
        working day."
				section="Web Experiences Banner"
        page="Web Experiences page"
       
			/>
		</section>
	);
}

export default WebExperiences;