import React from "react";
import Layout from "../components/Layout";
import SolutionsHelmet from "../components/SolutionsHelmet/SolutionsHelmet";
import WebExperiences from "../components/Solutions/WebExperiences/WebExperiences";
import MultiCardSection from "../components/Solutions/MultiCardSection/MultiCardSection";
import OurPerspectives from "../components/Solutions/OurPerspectives/OurPerspectives";
import LearnAboutUs from "../components/Solutions/LearnAboutUs/LearnAboutUs";
import { Helmet } from "react-helmet";

function solutionsWebExperiences() {
  const scrollTop = () => {
    window.scroll(0, 1);
  };
  return (
    <Layout> <Helmet>
    <meta charSet="utf-8" />
       <title>
   Hashout Technologies -  Web Experiences       </title>
       <meta
         name="description"
         content="Our solutions architects with vast expertise on a variety of platforms, frameworks, and technologies help you build your future web experience on a stack to deliver incredible web experiences at scale."
       ></meta></Helmet>
      <SolutionsHelmet />
      <WebExperiences />
      <MultiCardSection />
      <OurPerspectives />
      <LearnAboutUs page="web-experiences"/>
      <a className="go-to-top" href="javascript:void(0);" onClick={scrollTop}>
        <img
          className="go-to-top__img"
          src="../../solutions/go-to-top.png"
          alt="Go to top"
        />
      </a>
    </Layout>
  );
}
export default solutionsWebExperiences;
